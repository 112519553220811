import './App.css'
import Editor from './Editor'
import Viewer from './Viewer'

function App() {
  const editorOptions = {
    lineNumbers: true,
    readOnly: false,
    mode: 'markdown',
  }
  return (
    <div className="App">
      <div className="thirdPanel">
        <h1>Editor</h1>
        <Editor code="# Hello World!" options={editorOptions}></Editor>
      </div>
      <div className="thirdPanel">
        <h1>Remote Rendered</h1>
        <Viewer code="# Hello there!"></Viewer>
      </div>
      <div className="thirdPanel">
        <h1>Locally Rendered</h1>
      </div>
    </div>
  )
}

export default App
