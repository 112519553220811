import { useState, useEffect } from 'react'

function Viewer(props) {
  const [data, setData] = useState({ rendered: '' })

  useEffect(() => {
    (async () => {
      const resp = await fetch('api/document/render', {
        method: 'POST',
        body: props.code,
      })
      setData({ rendered: await resp.text() })
    })()
  }, [props.code]);

  return (<div dangerouslySetInnerHTML={{__html: data.rendered}}></div>)
}

export default Viewer
