import Codemirror from 'react-codemirror'

import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/xml/xml'
import 'codemirror/mode/markdown/markdown'

function Editor(props) {
  return (
    <div>
      <Codemirror value={props.code} options={props.options} autoFocus={true}></Codemirror>
    </div>
  )
}

export default Editor
